import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import ianz from "../media/ianz.jpg";

// eslint-disable-next-line
export const AccreditationPageTemplate = ({
  title,
  helmet,
  content,
  contentComponent,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section">
      {helmet || ""}
      <div className="container content content-border">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <h1 className="title is-size-2 has-text-weight-bold is-bold-light">
              {title}
            </h1>
            <hr />
            <PostContent content={content} />
            <hr />
            <h2>Certificates of Accreditation</h2>
            <p>
              <a
                href="https://cabis.ianz.govt.nz/ianzwebportal/ViewScope.aspx?Program=ce56ac51-b120-4762-bc76-eca77138a7df"
                className="has-text-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Pathlab Bay of Plenty</strong>
              </a>
            </p>
            <p>
              <a
                href="https://cabis.ianz.govt.nz/ianzwebportal/ViewScope.aspx?Program=d84eedf1-1da1-4511-bd75-5f0fda508396"
                className="has-text-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Pathlab Whakatane</strong>
              </a>
            </p>
            <p>
              <a
                href="https://cabis.ianz.govt.nz/ianzwebportal/ViewScope.aspx?Program=deee8563-b56b-4a4c-93f7-aaadc62b38bb"
                className="has-text-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Pathlab Waikato</strong>
              </a>
            </p>
            <p>
              <a
                href="https://cabis.ianz.govt.nz/ianzwebportal/ViewScope.aspx?Program=0f893a37-9610-48dd-beeb-ffe3353fe9ce"
                className="has-text-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Pathlab Rotorua</strong>
              </a>
            </p>
            <p>
              <a
                href="https://cabis.ianz.govt.nz/ianzwebportal/ViewScope.aspx?Program=ef23fa41-64c3-4175-b7f0-7579f6e2d79b"
                className="has-text-primary"
                target="_blank"
                rel="noopener noreferrer"
              >
                <strong>Pathlab Taupo</strong>
              </a>
            </p>
            <hr />
            <a
              href="https://www.ianz.govt.nz/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={ianz}
                alt="IANZ logo"
                style={{ width: "100px", maxHeight: "3em" }}
              />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

AccreditationPageTemplate.propTypes = {
  helmet: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
};

const Accreditation = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AccreditationPageTemplate
        helmet={
          <Helmet titleTemplate="%s | Pathlab">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        content={post.html}
        contentComponent={HTMLContent}
      />
    </Layout>
  );
};

Accreditation.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default Accreditation;

export const pageQuery = graphql`
  query AccreditationByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
